/* eslint-disable no-restricted-globals */
import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../store/store";

export const TableMutasi = (props: any) => {
  const { data } = props;
  const globalStateUser = useSelector((state: RootState) => {
    return state.userData;
  });
  const token = globalStateUser.token;

  const handleClick = (data: any) => {
    Swal.fire({
      title: "Edit Proyeksi",
      // text: `Anda Mengedit ${product.name} pada ${data.branch} Pada week ${data.week_start} - ${data.week_end}`,
      html: `<p>Anda Mengedit ${data.code}</p>`,
      input: "number",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Save",
      showLoaderOnConfirm: true,
      inputValue: data.in,
      preConfirm: (submitChanges) => {
        const changeData = {
          code: data.code,
          product_id: data.product_id,
          total: submitChanges,
        };
        return axios
          .put(process.env.REACT_APP_PRODUCT_SKU_IN as string, changeData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            location.reload();
            Swal.fire({
              title: `Data Telah Diubah!`,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th />
              <th>Tanggal</th>
              <th className="text-center">PO Code</th>
              <th>Kode</th>
              <th>Tenant</th>
              <th>Product Name</th>
              <th>Mode</th>
              <th>Opening</th>
              <th>In</th>
              <th>Out</th>
              <th>Left</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <th></th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>No Data</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ) : (
              data.map((data: any, idx: number) => {
                return (
                  <tr key={idx}>
                    <th>{idx + 1}</th>
                    <td>{data.date}</td>
                    <td className="text-center">{data.po_code || "-"}</td>
                    <td>{data.code}</td>
                    <td>{data?.tenant === undefined ? "DC" : data?.tenant}</td>
                    <td>{data?.product_name}</td>
                    <td>{data.mode}</td>
                    <td>{data.opening}</td>
                    {data.mode === "IN" ? (
                      <td
                        className="cursor-pointer"
                        onClick={() => handleClick(data)}
                      >
                        {data.in}
                      </td>
                    ) : (
                      <td>{data.in}</td>
                    )}
                    <td>{data.out}</td>
                    <td>{data.left}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
