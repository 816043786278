import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Drawer } from "./components/Drawer/Drawer";
import { Login } from "./pages/Login/Login";
import { Routes, Route } from "react-router-dom";
import { Paid } from "./pages/Paid/Paid";
import { Taken } from "./pages/Taken/Taken";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { Stock } from "./pages/Stock/Stock";
import { Unpaid } from "./pages/Unpaid/Unpaid";
import { EditPaid } from "./pages/EditPaid/EditPaid";
import { InputStock } from "./pages/InputStock/InputStock";
import { AddNewBranch } from "./pages/AddNewBranch/AddNewBranch";
import { AddNewProduct } from "./pages/AddNewProduct/AddNewProduct";
import { MutasiGudang } from "./pages/MutasiGudang/MutasiGudang";
import { MutasiTenant } from "./pages/MutasiTenant/MutasiTenant";
import { EnDisProduct } from "./pages/EnDisProduct/EnDisProduct";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route element={<Drawer />}>
            {/**
             * God Mode Only
             */}
            {/* <Route path='/addnewbranch' element={<AddNewBranch />} />
            <Route path='/addnewproduct' element={<AddNewProduct />} />
            */}
            {/* <Route path='/inputstock' element={<InputStock />} /> */}
            {/**
             * God Mode Only
             */}

            {/**
             * Common user
             */}
            <Route path="/paid" element={<Paid />} />
            <Route path="/taken" element={<Taken />} />
            {/**
             * Common user
             */}

            {/**
             * God Mode Only
             */}
            {/* <Route path="/unpaid" element={<Unpaid />} />
            <Route path="/setpaid" element={<EditPaid />} />
            <Route path="/endisproduct" element={<EnDisProduct />} /> */}
            {/**
             * God Mode Only
             */}
            <Route path="/stock" element={<Stock />} />
            <Route path="/mutasigudang" element={<MutasiGudang />} />
            {/* <Route path='/mutasitenant' element={<MutasiTenant />} /> */}
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
